import React, { useState } from "react";
import './AddHardwarProduct.css';
import { API_URL } from "../../Config";
import { useLocation, useParams } from "react-router-dom";
import { SideMenu } from "../../SideMenu";

const AddHardwareProduct = () => {
    const [photoFile, setPhotoFile] = useState(null);
    const queryParams = new URLSearchParams(useLocation().search);
    const selectedType = queryParams.get('selectedType');
    const [formData, setFormData] = useState({
        productCode: '',
        productName: '',
        companyName: '',
        productPrice: '',
        productSize: '',

    });
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setPhotoFile(file);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleProduct = async () => {
        try {

            const formDataWithPhoto = new FormData();
            formDataWithPhoto.append("photo", photoFile);
            formDataWithPhoto.append("productCode", formData.productCode);
            formDataWithPhoto.append("productName", formData.productName);
            formDataWithPhoto.append("companyName", formData.companyName);
            formDataWithPhoto.append("productPrice", formData.productPrice);
            formDataWithPhoto.append("productSize", formData.productSize);

            const response = await fetch(API_URL + `/addProduct/${selectedType}`, {
                method: 'POST',
                body: formDataWithPhoto,
            });

            if (response.status === 200) {
                window.location.href = '/SelectionDatabase'
                alert('Product added successfully!');
            } else if (response.status === 401) {
                alert('Invalid Document.');
            } else {
                alert('Already exists');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <SideMenu></SideMenu>

            <div className="main">
                <h4 className="addCompanyName">ADD PRODUCT IN {selectedType || "APPLIANCES"}</h4><br />
                <h3 className="enterProductCode_addHardwareProduct">Enter Product Code</h3>
                <input type="text1" className="enterProductCode_input" placeholder="Enter Product Code" name="productCode" value={formData.productCode}
                    onChange={handleInputChange} ></input>
                {/* <h3 className="enterColorCode">Enter Color Code</h3>
            <input type="text1" className="enterColorCode_input" placeholder="Enter "></input> */}
                <h3 className="enterName">Enter the Name</h3>
                <input type="text1" className="enterName_input" placeholder="Enter Product Name " name="productName" value={formData.productName}
                    onChange={handleInputChange} ></input>

                <h3 className="enterName">Enter Company</h3>
                <input type="text1" className="enterName_input" placeholder="Enter Comapny Name " name="companyName" value={formData.companyName}
                    onChange={handleInputChange} ></input>

                <h3 className="enterName">Enter Price</h3>
                <input type="text1" className="enterName_input" placeholder="Enter Product Price " name="productPrice" value={formData.productPrice}
                    onChange={handleInputChange} ></input>

                <h3 className="enterName">Enter Size</h3>
                <input type="text1" className="enterName_input" placeholder="Enter Product Size " name="productSize" value={formData.productSize}
                    onChange={handleInputChange} ></input>

                <h3 className="UploadImage">Upload Image</h3>
                <input type="file" className="upload_input" accept="image/*" onChange={handlePhotoChange} />
                <img className="upload_img" src={photoFile ? URL.createObjectURL(photoFile) : "../assets/upload-image.png"} alt="upload-img" />

                <div className="next_Btn_position_addProduct">
                    <button className="nextBtn" type="submit" onClick={handleProduct}>ADD</button>
                </div>

            </div>
        </div>
    )
};

export default AddHardwareProduct;
