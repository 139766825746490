// DiscountInput.js

import React from 'react';
import { useSelection } from './SelectionContext';
import { useNavigate } from 'react-router-dom';

const DiscountInput = () => {

  const navigate = useNavigate();
  const { selfDiscount, setSelfDiscount, clientDiscount, setClientDiscount, calculatePrice } = useSelection();

  const handleSelfDiscountChange = (e) => {
    setSelfDiscount(parseFloat(e.target.value) || 0);
    calculatePrice(); // Recalculate total price when self discount changes
  };

  const handleClientDiscountChange = (e) => {
    setClientDiscount(parseFloat(e.target.value) || 0);
    calculatePrice(); // Recalculate total price when client discount changes
  };
  const handleFinalSubmit = () => {
    navigate('/QuotationPdf');
  }

  return (
    <div className="discount-input-container">
      <h2>Discounts</h2>
      <div className="discount-input">
        <label>
          Self Discount (%):
          <input type="number" value={selfDiscount} onChange={handleSelfDiscountChange} />
        </label>
      </div>
      <div className="discount-input">
        <label>
          Client Discount (%):
          <input type="number" value={clientDiscount} onChange={handleClientDiscountChange} />
        </label>
      </div>
      <div className="submit_Btn_position_Selection">
        <button className="submitBtn" type="submit" onClick={handleFinalSubmit} ><img className='img_tick_Selection' src='../assets/tick.png'></img></button>
      </div>
    </div>
  );
};

export default DiscountInput;
