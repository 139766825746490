import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../Config';

const PdfHistory = () => {
  const [pdfs, setPdfs] = useState([]);

  useEffect(() => {
    // Fetch the list of stored PDFs from the server
    const fetchPdfs = async () => {
      try {
        const response = await axios.get(API_URL + '/pdf/get-pdfs');
        setPdfs(response.data);
      } catch (error) {
        console.error('Error fetching PDFs:', error);
      }
    };

    fetchPdfs();
  }, []);

  const downloadPdf = async (pdfId) => {
    try {
      // Directly download the PDF file from the server's "pdfs" folder

      window.open(`https://quotsync.inittechnology.co/Quotsync_backend/pdfs/${pdfId}.pdf`, '_blank');
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <div>
      <h1>Generated PDF History</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Client Name</th>
            <th>Contact Number</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {pdfs.map((pdf) => (
            <tr key={pdf.id}>
              <td>{pdf.id}</td>
              <td>{pdf.clientName}</td>
              <td>{pdf.contactNumber}</td>
              <td>{pdf.createdAt}</td>
              <td>
                <button onClick={() => downloadPdf(pdf.id)}>Download</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PdfHistory;
