import React, { useEffect, useState } from "react";
import { API_URL } from "../../Config";
import './ProductInfo.css'
import { useNavigate, useParams } from "react-router-dom";
import { SideMenu } from "../../SideMenu";


const ProductInfo = () => {
  const navigate = useNavigate();
  const { selectedType } = useParams();
  const [names, setNames] = useState([]);
  const [editedPrices, setEditedPrices] = useState({});
  const [filterValue, setFilterValue] = useState('');


  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    fetch(API_URL + `/${selectedType}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const updatedData = data.map(product => {
          const { photo, ...productWithoutPhoto } = product;
          return { ...productWithoutPhoto };
        });
        setNames(updatedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const filteredProducts = names.filter((product) => {
    const productValues = Object.values(product).join(' ').toLowerCase();
    return productValues.includes(filterValue.toLowerCase());
  });

  const renderTableHeader = () => {
    if (names.length === 0) return null;

    const columns = Object.keys(names[0]);

    return (
      <div className="row1_ProductInfo">
        {columns.map((column, index) => (
          <div key={index} className={`column${index + 1}_row1_ProductInfo`}>
            <p>{column}</p>
          </div>
        ))}
      </div>
    );
  };
  const handlePriceChange = (rowIndex, newValue, productId) => {

    setEditedPrices((prevEditedPrices) => ({
      ...prevEditedPrices,
      [productId]: newValue,
    }));
  };

  const handleFinalSubmit = async () => {
    try {
      // Iterate over the edited prices and send them to the server
      for (const productId in editedPrices) {
        const newPrice = editedPrices[productId];
        await updatePriceOnServer(productId, newPrice);
      }
      // Clear the editedPrices state after successful submission
      setEditedPrices({});
      fetchProducts();
    } catch (error) {
      console.error('Error during final submission:', error);
    }
  };

  const updatePriceOnServer = async (productId, newPrice) => {
    try {
      const response = await fetch(API_URL + `/${selectedType}/${productId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ price: newPrice }),
      });

      if (!response.ok) {
        throw new Error('Failed to update price on the server');
      }
      window.alert('Price updated successfully')

    } catch (error) {
      console.error('Error updating price on the server:', error);
    }
  };



  const renderTableRows = () => {

    return filteredProducts.map((product, rowIndex) => (
      <div key={rowIndex} className="row2_ProductInfo">
        {Object.entries(product).map(([key, value], colIndex) => (
          <div key={colIndex} className={`column${colIndex + 1}_row2_ProductInfo`}>
            {key === 'name' ? (
              <div className="label_row2_ProductInfo" title={value}>
                {value}
              </div>
            ) : (
              <div className="label_row2_ProductInfo">
                {key === 'price' ? (
                  <span
                    onClick={() => { }}
                    contentEditable={true}
                    onBlur={(e) => handlePriceChange(rowIndex, e.target.innerText, product.code)}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                ) : (
                  <span>{value}</span>
                )}
              </div>
            )}
          </div>
        ))}
        <div className="column7_row2_ProductInfo">
          <a onClick={() => handleDeleteProduct(product.name)}><img src="../assets/Trash.png" alt="trash-icon" className="dustbin" /></a>
        </div>
      </div>
    ));
  };

  const handleAddProduct = () => {
    navigate(`/addHardwarProduct?selectedType=${selectedType}`);
  }

  const handleDeleteProduct = async (productId) => {
    try {
      // Display a confirmation dialog
      const userConfirmed = window.confirm('Are you sure you want to delete this product?');

      if (!userConfirmed) {
        // If the user cancels the deletion, exit the function
        return;
      }
      const response = await fetch(API_URL + `/${selectedType}/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Include any additional headers needed for authentication or other purposes
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      // If the API call is successful, update the UI state (remove product from names)
      const updatedNames = names.filter((product) => product.code !== productId);
      setNames(updatedNames);

      console.log('Product deleted successfully');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSearchInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <div>
      <SideMenu></SideMenu>
      <div className="main_ProductInfo">
        <div className="add_Btn_position">
          <button className="addBtn" type="submit" onClick={handleAddProduct}><img className="plus_img" src="../assets/plus.png" alt="plusImage" />&nbsp;</button>
        </div>

        <div className='searchBar_pos_productInfo'>
          &nbsp;<img className='search_icon' src='../assets/search.png' alt='search' />
          <input className='searchInput' type="search" id="gsearch" name="gsearch" placeholder='Search' value={filterValue}
            onChange={handleSearchInputChange}></input>
        </div>

        {renderTableHeader()}
        {renderTableRows()}

      </div>

      <div className="submit_Btn_position_Selection">
        <button className="submitBtn" type="submit" onClick={handleFinalSubmit} ><img className='img_tick_Selection' src='../assets/tick.png'></img></button>
      </div>
    </div>

  )
};

export default ProductInfo;