// SelectionContext.js
import React, { createContext, useContext, useState } from 'react';
import { API_URL } from '../../Config';

const SelectionContext = createContext();

export function useSelection() {
  return useContext(SelectionContext);
}

export function SelectionProvider({ children }) {
  const [kitchenType, setKitchenType] = useState('');
  const [lowerDimention, setLowerDimention] = useState('');
  const [upperDimention, setUpperDimention] = useState('');
  const [loftDimention, setLoftDimention] = useState('');
  const [tallDimention, setTallDimention] = useState('');
  const [islandDimention, setIslandDimention] = useState('');
  const [company, setCompany] = useState('');
  const [category, setCategory] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsForStone, setSelectedProductsForStone] = useState([]);
  const [selectedProductsForShutter, setSelectedProductsForShutter] = useState([]);
  const [selectedProductsForCarcass, setSelectedProductsForCarcass] = useState([]);
  const [selectedProductsForAppliances, setSelectedProductsForAppliances] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalForProducts, setTotalForProducts] = useState(0);
  const [totalForStones, setTotalForStones] = useState(0);
  const [totalForShutter, setTotalForShutter] = useState(0);
  const [totalForAppliances, setTotalForAppliances] = useState(0);
  const [totalForCarcase, setTotalForCarcase] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [selfDiscount, setSelfDiscount] = useState(0);
  const [clientDiscount, setClientDiscount] = useState(0);

  const extractDimensions = (dimension) => {
    const [width, height] = dimension.split('x').map(Number);
    return { width, height };
  };

  const resetState = () => {
    // Add logic to reset your state here
    setKitchenType('');
    setLowerDimention('');
    setUpperDimention('');
    setLoftDimention('');
    setTallDimention('');
    setIslandDimention('');
    setCompany('');
    setCategory('');
    setSelectedProducts([]);
    setSelectedProductsForStone([]);
    setSelectedProductsForShutter([]);
    setSelectedProductsForCarcass([]);
    setSelectedProductsForAppliances([]);
    setTotalPrice(0);
    setTotalForProducts(0);
    setTotalForStones(0);
    setTotalForShutter(0);
    setTotalForAppliances(0);
    setTotalForCarcase(0);
    setTotalAfterDiscount(0);
    setSelfDiscount(0);
    setClientDiscount(0);
  };

//======================================== Price Calulation ========================================================
  const calculatePrice = () => {

    const lowerUnitDimensions = extractDimensions(lowerDimention);
    const upperUnitDimensions = extractDimensions(upperDimention);
    const loftUnitDimensions = extractDimensions(loftDimention);
    const islandUnitDimensions = extractDimensions(islandDimention);
    const tallUnitDimensions = extractDimensions(tallDimention);

    const lowerUnitArea = lowerUnitDimensions.width * lowerUnitDimensions.height;
    const upperUnitArea = upperUnitDimensions.width * upperUnitDimensions.height;
    const loftUnitArea = loftUnitDimensions.width * loftUnitDimensions.height;
    const islandUnitArea = islandUnitDimensions.width * islandUnitDimensions.height;
    const tallUnitArea = tallUnitDimensions.width * tallUnitDimensions.height;

    const totalArea = lowerUnitArea + upperUnitArea + loftUnitArea + islandUnitArea + tallUnitArea;

    const selectedProductNames = selectedProducts.map((product) => product.productName).join(',');
    const selectedStoneNames = selectedProductsForStone.map((product) => product.productName).join(',');
    const selectedShutterNames = selectedProductsForShutter.map((product) => product.productName).join(',');
    const selectedAppliancesNames = selectedProductsForAppliances.map((product) => product.productName).join(',');
    const selectedCarcassNames = selectedProductsForCarcass.map((product) => product.productName).join(',');

    const url = API_URL + `/hardware/product-prices?selectedProducts=${encodeURIComponent(selectedProductNames)}`;
    const urlForStone = API_URL + `/stone/stone-prices?selectedProducts=${encodeURIComponent(selectedStoneNames)}`;
    const urlForShutter = API_URL + `/shutter/shutter-prices?selectedProducts=${encodeURIComponent(selectedShutterNames)}`;
    const urlForAppliances = API_URL + `/appliances/appliances-prices?selectedProducts=${encodeURIComponent(selectedAppliancesNames)}`;
    const urlForCarcass = API_URL + `/carcass/carcass-prices?selectedProducts=${encodeURIComponent(selectedCarcassNames)}`;

 //======================================== Price Calulation For hardware ========================================================

    const pricePromises = selectedProducts.map((product) => {
      const queryParams = {
        productName: product.productName || '',
        size: product.size || '',
      };

      const queryString = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return fetch(`${url}&${queryString}`)
        .then((response) => response.json())
        .then((productPrice) => {
          const productQuantity = parseInt(product.quantity, 10) || 1;

          if (productPrice) {
            return Number(productPrice.price) * productQuantity;
          } else {
            console.error(`Product price not found for ${product.productName}`);
            return 0;
          }
        })
        .catch((error) => {
          console.error(`Error fetching product price for ${product.productName}:`, error);
          return 0;
        });
    });

//======================================== Price Calulation For Stone ========================================================
    const pricePromisesForStone = selectedProductsForStone.map((product) => {
      const queryParams = {
        productName: product.productName || '',
        size: product.size || '',
      };

      const queryString = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return fetch(`${urlForStone}&${queryString}`)
        .then((response) => response.json())
        .then((productPrice) => {
          const productQuantity = parseInt(product.quantity, 10) || 1;

          if (productPrice) {
            return Number(productPrice.price) * productQuantity;
          } else {
            console.error(`Product price not found for ${product.productName}`);
            return 0;
          }
        })
        .catch((error) => {
          console.error(`Error fetching product price for ${product.productName}:`, error);
          return 0;
        });
    });

//======================================== Price Calulation For Shutter ========================================================
    const pricePromisesForShutter = selectedProductsForShutter.map((product) => {
      const queryParams = {
        productName: product.productName || '',
        size: product.size || '',
      };

      const queryString = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return fetch(`${urlForShutter}&${queryString}`)
        .then((response) => response.json())
        .then((productPrice) => {
          const productQuantity = parseInt(product.quantity, 10) || 1;

          if (productPrice) {
            return Number(productPrice.price) * productQuantity;
          } else {
            console.error(`Product price not found for ${product.productName}`);
            return 0;
          }
        })
        .catch((error) => {
          console.error(`Error fetching product price for ${product.productName}:`, error);
          return 0;
        });
    });

  //======================================== Price Calulation For Appliances ========================================================
    const pricePromisesForAppliances = selectedProductsForAppliances.map((product) => {
      const queryParams = {
        productName: product.productName || '',
        size: product.size || '',
      };

      const queryString = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return fetch(`${urlForAppliances}&${queryString}`)
        .then((response) => response.json())
        .then((productPrice) => {
          const productQuantity = parseInt(product.quantity, 10) || 1;

          if (productPrice) {
            return Number(productPrice.price) * productQuantity;
          } else {
            console.error(`Product price not found for ${product.productName}`);
            return 0;
          }
        })
        .catch((error) => {
          console.error(`Error fetching product price for ${product.productName}:`, error);
          return 0;
        });
    });

 //======================================== Price Calulation For Carcass ========================================================
    const pricePromisesForCarcass = selectedProductsForCarcass.map((product) => {
      const queryParams = {
        productName: product.productName || '',
        size: product.size || '',
      };

      const queryString = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return fetch(`${urlForCarcass}&${queryString}`)
        .then((response) => response.json())
        .then((productPrice) => {
          const productQuantity = parseInt(product.quantity, 10) || 1;

          if (productPrice) {
            return Number(productPrice.price) * productQuantity * totalArea;
          } else {
            console.error(`Product price not found for ${product.productName}`);
            return 0;
          }
        })
        .catch((error) => {
          console.error(`Error fetching product price for ${product.productName}:`, error);
          return 0;
        });
    });

    const allPromises = [...pricePromises, ...pricePromisesForStone, ...pricePromisesForShutter, ...pricePromisesForAppliances, ...pricePromisesForCarcass];

    Promise.all(allPromises)
      .then((prices) => {
        const totalProductsLength = selectedProducts.length;
        const totalStonesLength = selectedProductsForStone.length;
        const totalShutterLength = selectedProductsForShutter.length;
        const totalAppliancesLength = selectedProductsForAppliances.length;
        const totalCarcassLength = selectedProductsForCarcass.length;

        const totalPriceForProducts = prices.slice(0, totalProductsLength).reduce((acc, price) => acc + price, 0);
        const totalPriceForStones = prices.slice(totalProductsLength, totalProductsLength + totalStonesLength).reduce((acc, price) => acc + price, 0);
        const totalPriceForShutter = prices.slice(totalProductsLength + totalStonesLength, totalProductsLength + totalStonesLength + totalShutterLength).reduce((acc, price) => acc + price, 0);
        const totalPriceForAppliances = prices.slice(totalProductsLength + totalStonesLength + totalShutterLength, totalProductsLength + totalStonesLength + totalShutterLength + totalAppliancesLength).reduce((acc, price) => acc + price, 0);
        const totalPriceForCarcass = prices.slice(totalProductsLength + totalStonesLength + totalShutterLength + totalAppliancesLength, totalProductsLength + totalStonesLength + totalShutterLength + totalAppliancesLength + totalCarcassLength).reduce((acc, price) => acc + price, 0);

        const totalPriceBeforeDiscounts = totalPriceForProducts + totalPriceForStones + totalPriceForShutter + totalPriceForAppliances + totalPriceForCarcass;

        const totalPriceAfterSelfDiscount = totalPriceBeforeDiscounts * (1 - selfDiscount / 100);
        const totalPriceAfterClientDiscount = totalPriceAfterSelfDiscount * (1 - clientDiscount / 100);

        setTotalForProducts(totalPriceForProducts);
        setTotalForStones(totalPriceForStones);
        setTotalForShutter(totalPriceForShutter);
        setTotalForAppliances(totalPriceForAppliances);
        setTotalForCarcase(totalPriceForCarcass);

        let totalAfterDiscount;

        if (totalPriceAfterClientDiscount > 0) {
          totalAfterDiscount = totalPriceAfterClientDiscount;
        } else if (totalPriceAfterSelfDiscount > 0) {
          totalAfterDiscount = totalPriceAfterSelfDiscount;
        } else {
          totalAfterDiscount = 0;
        }

        setTotalAfterDiscount(totalAfterDiscount);

        const combinedTotal = totalPriceForProducts + totalPriceForStones + totalPriceForShutter + totalPriceForAppliances + totalPriceForCarcass;
        setTotalPrice(combinedTotal);
      })
      .catch((error) => {
        console.error('Error calculating total price:', error);
      });
  };

//======================================== End calculation ========================================================

  const value = {
    kitchenType,
    setKitchenType,
    lowerDimention,
    setLowerDimention,
    upperDimention,
    setUpperDimention,
    tallDimention,
    setTallDimention,
    loftDimention,
    setLoftDimention,
    islandDimention,
    setIslandDimention,
    company,
    setCompany,
    category,
    setCategory,
    selectedProducts,
    setSelectedProducts,
    selectedProductsForStone,
    setSelectedProductsForStone,
    selectedProductsForShutter,
    setSelectedProductsForShutter,
    selectedProductsForCarcass,
    setSelectedProductsForCarcass,
    selectedProductsForAppliances,
    setSelectedProductsForAppliances,
    totalForProducts,
    setTotalForProducts,
    totalForStones,
    setTotalForStones,
    totalForCarcase,
    setTotalForCarcase,
    totalForShutter,
    setTotalForShutter,
    totalForAppliances,
    setTotalForAppliances,
    selfDiscount,
    setSelfDiscount,
    clientDiscount,
    setClientDiscount,
    totalAfterDiscount,
    setTotalAfterDiscount,
    totalPrice,
    calculatePrice,
    resetState,
  };

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  );
}
