import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './component/signIn/SignIn';
import ForgetPassword from './component/forgetPassword/ForgetPassword';
import Selection from './component/selectionPage/Selection';
import Settings from './component/settings/setting';
import ChangePassword from './component/changePassword/ChangePassword';
import ChangeUsername from './component/changeUsername/ChangeUsername';
import AddCategory from './component/addCategory/addCategory';
import KitchenTypes from './component/kitchenType/kitchenTypes';
import { SelectionProvider } from './component/test1/SelectionContext';
import HardwareProductName from './component/Hardware/HardwareProductName';
import KitchenDimesnions from './component/KitchenDimentions/KitchenDimensions';
import AddHardwarCompany from './component/addCompany/AddHardwareCompany';
import AddHardwareProduct from './component/addCompany/AddHardwarProduct';
import SelectHardwareCompany from './component/Hardware/HardwareCompanies';
import GeneratePDF from './component/test1/GeneratedPDF';
import SelectStoneProduct from './component/Stone/StoneProduct';
import Quotation from './component/test1/Quotation';
import SelectionDatabase from './component/selectionPage/SelectionDatabase';
import ProductInfo from './component/productInfo/ProductInfo';
import DiscountInput from './component/test1/DiscountInput';
import PdfHistory from './Cloud/PdfHistory';
import Cloud from './Cloud/Cloud';
import Preview from './component/test1/Preview';
import SelectShutterProduct from './component/Stone/ShutterProduct';
import SelectAppliancesProduct from './component/Stone/AppliancesProduct';
import SelectCarcassProduct from './component/Stone/CarcassProduct';
import { useAuth } from './AuthContext';

function App() {
  const { state } = useAuth();
  return (
    <div>
      <SelectionProvider>
        <Router>
          <Routes>
          {state.isAuthenticated ? (
              <>
                {/* Authenticated Routes */}
                <Route path='/ChangePassword' element={<ChangePassword />} />
            <Route path='/ChangeUsername' element={<ChangeUsername />} />
            <Route path='/AddCategory' element={<AddCategory />} />
            <Route path='/KitchenTypes' element={<KitchenTypes />} />
            <Route path='/Settings' element={<Settings />} />
            <Route path='/Selection' element={<Selection />} />
            {/* <Route path='/signIn' element={<SignIn />} />
            <Route path='/forgetPassword' element={<ForgetPassword />} /> */}
            <Route path='/hardware-product' element={<HardwareProductName />} />
            <Route path='/KitchenDimentions' element={<KitchenDimesnions />} />
            <Route path='/addHardwarCompany' element={<AddHardwarCompany />} />
            <Route path='/addHardwarProduct' element={<AddHardwareProduct />} />
            <Route path='/HARDWARE' element={<SelectHardwareCompany />} />
            <Route path='/STONE' element={<SelectStoneProduct />} />
            <Route path='/SHUTTER' element={<SelectShutterProduct />} />
            <Route path='/APPLIANCES' element={<SelectAppliancesProduct />} />
            <Route path='/CARCASS' element={<SelectCarcassProduct />} />
            <Route path='/SelectionDatabase' element={<SelectionDatabase />} />
            <Route path='/productInfo/:selectedType' element={<ProductInfo />} />

            <Route path='/generate-pdf' element={<GeneratePDF />} />
            <Route path='/QuotationPdf' element={<Quotation />} />
            <Route path='/Discount' element={<DiscountInput />} />
            <Route path='/PdfHistory' element={<PdfHistory />} />

            <Route path='/Cloud' element={<Cloud />} />
            <Route path='/Preview' element={<Preview />} />
               
              </>
            ) : (
              // Unauthenticated Route
              <Route path='/signIn' element={<SignIn />} />
            )}
            <Route path='/' element={<SignIn />} />
            <Route path='/signIn' element={<SignIn />} />
            <Route path='/forgetPassword' element={<ForgetPassword />} />
            {/* <Route path='/' element={<SignIn />} />
            <Route path='/ChangePassword' element={<ChangePassword />} />
            <Route path='/ChangeUsername' element={<ChangeUsername />} />
            <Route path='/AddCategory' element={<AddCategory />} />
            <Route path='/KitchenTypes' element={<KitchenTypes />} />
            <Route path='/Settings' element={<Settings />} />
            <Route path='/Selection' element={<Selection />} />
            <Route path='/signIn' element={<SignIn />} />
            <Route path='/forgetPassword' element={<ForgetPassword />} />
            <Route path='/hardware-product' element={<HardwareProductName />} />
            <Route path='/KitchenDimentions' element={<KitchenDimesnions />} />
            <Route path='/addHardwarCompany' element={<AddHardwarCompany />} />
            <Route path='/addHardwarProduct' element={<AddHardwareProduct />} />
            <Route path='/HARDWARE' element={<SelectHardwareCompany />} />
            <Route path='/STONE' element={<SelectStoneProduct />} />
            <Route path='/SHUTTER' element={<SelectShutterProduct />} />
            <Route path='/APPLIANCES' element={<SelectAppliancesProduct />} />
            <Route path='/CARCASS' element={<SelectCarcassProduct />} />
            <Route path='/SelectionDatabase' element={<SelectionDatabase />} />
            <Route path='/productInfo/:selectedType' element={<ProductInfo />} />

            <Route path='/generate-pdf' element={<GeneratePDF />} />
            <Route path='/QuotationPdf' element={<Quotation />} />
            <Route path='/Discount' element={<DiscountInput />} />
            <Route path='/PdfHistory' element={<PdfHistory />} />

            <Route path='/Cloud' element={<Cloud />} />
            <Route path='/Preview' element={<Preview />} /> */}
          </Routes>
        </Router>
      </SelectionProvider>
    </div>
  );
}

export default App;
