import React, { useState } from "react";
import './ChangePassword.css'
import { API_URL } from "../../Config";

const ChangePassword = () => {

  const [successMessage, setSuccessMessage] = useState('');
  const [otpMessage, setOtpMessage] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    otp: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendOtp = async () => {

    formData.otp = formData.otp.toString();
    const response = await fetch(API_URL + '/send-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("data==>", data);
      setOtpMessage('Otp send!');
    }
  };

  const handlePassword = async () => {
    formData.otp = formData.otp.toString();
    try {
      const response = await fetch(API_URL + '/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setSuccessMessage('Password changed successfully!')
        setTimeout(() => {
          window.location.href = '/Settings';
        }, 2000);
      } else {
        alert('Invalid Otp');
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <div className="imgcontainer">
        <img src="../assets/Quotsync.png" alt="logo" className="avatar" />
      </div>
      <div className="container">
        <h2 className="change_password">Change Password</h2>
        <input type="text_ChangePswd" placeholder="Enter your Email" name="email" onChange={handleInputChange} /><br />
        <hr /><br />
        <input type="password_ChangePswd" placeholder="Enter OTP" name="otp" onChange={handleInputChange} />
        {otpMessage && <label>{otpMessage}</label>}<br />
        <hr /><br />
        <input type="password_ChangePswd" placeholder="Enter new Password" name="password" onChange={handleInputChange} />
        <hr /><br />
        <button className="update_btn_ChangePswd" type="submit" onClick={handleSendOtp}>Send Otp</button><br />
        {successMessage && <label>{successMessage}</label>}<br />
        <button className="update_btn_ChangePswd" type="submit" onClick={handlePassword}>Update</button>
      </div>
    </div>

  )
};

export default ChangePassword;