import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Cloud.css'
import { API_URL } from '../Config';
import { SideMenu } from '../SideMenu';

const Cloud = () => {
  const [pdfs, setPdfs] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  // Fetch the list of stored PDFs from the server
  const fetchPdfs = async () => {
    try {
      const response = await axios.get(API_URL + '/pdf/get-pdfs');
      setPdfs(response.data);
    } catch (error) {
      console.error('Error fetching PDFs:', error);
    }
  };

  useEffect(() => {

    fetchPdfs();
  }, []);

  // Function to format the UTC timestamp to local time
  const formatToLocalTime = (utcTimestamp) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(utcTimestamp).toLocaleString(undefined, options);
  };


  const downloadPdf = async (pdfId) => {
    try {
      // Directly download the PDF file from the server's "pdfs" folder

      window.open(`https://quotsync.inittechnology.co/Quotsync_backend/pdfs/${pdfId}.pdf`, '_blank');
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const filteredProducts = pdfs.filter((product) => {
    const productValues = Object.values(product).join(' ').toLowerCase();
    return productValues.includes(filterValue.toLowerCase());
  });

  const handleDeleteProduct = async (productId) => {
    try {
      // Display a confirmation dialog
      const userConfirmed = window.confirm('Are you sure you want to delete this product?');

      if (!userConfirmed) {
        // If the user cancels the deletion, exit the function
        return;
      }
      const response = await fetch(API_URL + `/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Include any additional headers needed for authentication or other purposes
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      // If the API call is successful, update the UI state (remove product from names)
      const updatedNames = pdfs.filter((product) => product.code !== productId);
      setPdfs(updatedNames);

      console.log('Product deleted successfully');
      fetchPdfs();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSearchInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  const renderTableHeader = () => {
    if (pdfs.length === 0) return null;

    const columns = Object.keys(pdfs[0]);

    return (
      <div className="row1_CloudInfo">
        {columns.map((column, index) => (
          <div key={index} className={`column${index + 1}_row1_CloudInfo`}>
            <p>{column}</p>
          </div>
        ))}
      </div>
    );
  };


  const renderTableRows = () => {

    return filteredProducts.map((product, rowIndex) => (
      <div key={rowIndex} className="row2_CloudInfo">
        {Object.entries(product).map(([key, value], colIndex) => (
          <div key={colIndex} className={`column${colIndex + 1}_row2_CloudInfo`}>
            {key === 'name' ? (
              <div className="label_row2_CloudInfo" title={value}>
                {value}
              </div>
            ) : (
              <div className="label_row2_CloudInfo">
                {key === 'createdAt' ? (
                  <span>{formatToLocalTime(value)}</span>
                ) : (
                  <span>{value}</span>
                )}
              </div>
            )}
          </div>
        ))}
        <div className="column6_row2_CloudInfo">
          <button onClick={() => downloadPdf(product.id)}>Download</button>
        </div>
        <div className="column7_row2_CloudInfo">
          <a onClick={() => handleDeleteProduct(product.id)} ><img src="../assets/Trash.png" alt="trash-icon" className="dustbin" /></a>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <SideMenu></SideMenu>
      <div className="main_CloudInfo">

        <div className='searchBar_pos_CloudInfo'>
          &nbsp;<img className='search_icon' src='../assets/search.png' alt='search' />
          <input className='searchInput' type="search" id="gsearch" name="gsearch" placeholder='Search' value={filterValue}
            onChange={handleSearchInputChange}></input>
        </div>

        {renderTableHeader()}
        {renderTableRows()}

      </div>

      <div className="submit_Btn_position_Selection">
        <button className="submitBtn" type="submit" ><img className='img_tick_Selection' src='../assets/tick.png'></img></button>
      </div>
    </div>

  )
};

export default Cloud;
