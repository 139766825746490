import React from "react";
import './Settings.css'
import { SideMenu } from "../../SideMenu";

const Settings = () => {
    const handleChangePassword = () => {
        window.location.href = '/ChangePassword'
    }

    const handleChangeUsername = () => {
        window.location.href = '/ChangeUsername'
    }

    const handleCategory = () => {
        window.location.href = '/AddCategory'
    }

    return (
        <div>
            <SideMenu></SideMenu>
            <div className="main_setting">
                <button className="changePassword_btn" type="submit" onClick={handleChangePassword}>Change Password</button><br />
                <button className="changeUsername_btn" type="submit" onClick={handleChangeUsername}>Change Username</button><br />
                {/* <button className="addNewCategory_btn" type="submit" onClick={handleCategory}>Add New Category</button> */}
            </div>
        </div>

    )
};
export default Settings;