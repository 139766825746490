import { useState } from 'react';
import './Preview.css'
import { useNavigate } from 'react-router-dom';
import { useSelection } from './SelectionContext';
import { SideMenu } from '../../SideMenu';

const Preview = () => {
  const [isInputVisible1, setIsInputVisible1] = useState(false);
  const [isInputVisible2, setIsInputVisible2] = useState(false);

  const toggleInput1 = () => {
    setIsInputVisible1(!isInputVisible1);
    setIsInputVisible2(false)
  };
  const toggleInput2 = () => {
    setIsInputVisible2(!isInputVisible2);
    setIsInputVisible1(false)
  };

  const navigate = useNavigate();
  const { selfDiscount, setSelfDiscount, clientDiscount, setClientDiscount, calculatePrice } = useSelection();

  const handleSelfDiscountChange = (e) => {
    setSelfDiscount(parseFloat(e.target.value) || 0);
    calculatePrice(); // Recalculate total price when self discount changes
  };

  const handleClientDiscountChange = (e) => {
    setClientDiscount(parseFloat(e.target.value) || 0);
    calculatePrice(); // Recalculate total price when client discount changes
  };
  const handleFinalSubmit = () => {
    navigate('/QuotationPdf');
  }


  return (
    <div>
      <SideMenu></SideMenu>
      <div className="container_preview">
        <div className="responsive-div">
          <button className='self_btn' onClick={toggleInput1}>SELF</button><br></br>
          {isInputVisible1 && (
            <>
              <input className='input1_of_preview' placeholder='Enter discount %' value={selfDiscount} onChange={handleSelfDiscountChange}></input>
              <br />
            </>
          )}

          <button className='franchise_btn' onClick={toggleInput2}>FRANCHISE</button><br />
          {isInputVisible2 && (
            <>
              <input className='input2_of_preview' placeholder='Enter discount %' value={clientDiscount} onChange={handleClientDiscountChange}></input>
            </>
          )}
        </div>
      </div>


      <div className="complete_btn_pos">
        <button className="complete_btn" type="submit" onClick={handleFinalSubmit}>Complete</button>
      </div>

    </div>

  )
};

export default Preview;