import React, { useEffect, useState } from 'react';
import './Selection.css'
import { API_URL } from '../../Config';
import { useSelection } from '../test1/SelectionContext';
import { SideMenu } from "../../SideMenu";
import { useNavigate } from 'react-router-dom';


const SelectionDatabase = () => {


  const [names, setNames] = useState([]);

  const navigate = useNavigate();
  const { category, setCategory } = useSelection();

  const handleCategoryTypeSelect = (selectedType) => {
    setCategory(selectedType);
    navigate(`/productInfo/${selectedType}`);
  };

  useEffect(() => {
    fetch(API_URL + '/category')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setNames(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
      <SideMenu></SideMenu>
      <div className="main_selection">
        {names.map((name, index) => (
          <a key={index} href={`#${name.name}`}>
            <button className='carcass' onClick={() => handleCategoryTypeSelect(name.name)}>{name.name}</button><br />
          </a>
        ))}

      </div>
    </div>

  )
};

export default SelectionDatabase;