import React, { useState } from "react";
import './AddHardwarCompany.css';
import { API_URL } from "../../Config";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../../SideMenu";

const AddHardwarCompany = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: '',
    photo: null,
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    // If the input is a file input, update the 'photo' field with the selected file
    if (type === 'file') {
      setFormData({
        ...formData,
        photo: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleComapny = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('companyName', formData.companyName);
    data.append('photo', formData.photo);
    console.log("data==>", formData.photo);

    try {
      const response = await fetch(API_URL + '/addCompany', {
        method: 'POST',
        body: data, // Use the FormData object instead of JSON.stringify
      });

      if (response.status === 200) {
        navigate('/Settings');
        alert('Category added successfully!');
      } else if (response.status === 401) {
        alert('Invalid Document.');
      } else {
        alert('Already exists');
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <SideMenu></SideMenu>

      <div className="main">
        <h4 className="addHardwareCompanies">ADD COMPANY</h4><br />
        <form encType="multipart/form-data">
          <h3 className="enterComapnyName">Enter Company Name</h3>
          <input type="text1" className="enterCompanyName_input" placeholder="Enter " name="companyName" onChange={handleInputChange}></input>
          <h3 className="enterComapnyLogo">Enter Company Logo</h3>
          <input type="file" name="photo" onChange={handleInputChange} />
          <img className="upload_img" src="../assets/upload-image.png" alt="upload-img"></img>

          <div className="next_Btn_position_addCompany">
            <button className="nextBtn" type="submit" onClick={handleComapny}>Add</button>
          </div>
        </form>

      </div>
    </div>
  )
};

export default AddHardwarCompany;

