// GeneratePDF.js
import React, { useEffect } from 'react';
import { useSelection } from './SelectionContext';
import {useNavigate} from 'react-router-dom';
import jsPDF from 'jspdf';
import './GeneratedPDF.css'

function GeneratePDF() {
  const navigate = useNavigate();
  const { kitchenType, lowerDimention, upperDimention, loftDimention, lslandDimention, company, category, selectedProducts, selectedProductsForStone, totalPrice, calculatePrice } = useSelection();

  // Calculate the total price when the component mounts
  useEffect(() => {
    calculatePrice();
  }, [selectedProducts, selectedProductsForStone, calculatePrice]);

  const downloadPDF = () => {
    const doc = new jsPDF();
    let suggestedFilename = 'selection.pdf';
        let userFilename = prompt('Enter a filename for the PDF (e.g., my-selection.pdf):');
        if( userFilename !== null){
          suggestedFilename = userFilename
        }
    const imagePath = '../assets/A4.png'; 
    doc.addImage(imagePath, 'JPEG', 0, 0, 210, 297);

    doc.setFontSize(12);
    doc.setTextColor(76, 65, 58);
  
    // Add your text content starting from the second page
    doc.text(`Kitchen Type: ${kitchenType}`, 60, 50);
    doc.text(`Lower Dimention: ${lowerDimention}`, 60, 60);
    doc.text(`Upper Dimention: ${upperDimention}`, 60, 70);
    doc.text(`Loft Dimention: ${loftDimention}`, 60, 80);
    doc.text(`Island Dimention: ${lslandDimention}`, 60, 90);
    doc.text(`Company Name: ${company}`, 60, 100);
    doc.text(`Category: ${category}`, 60, 110);
    doc.text('Selected Products:', 60, 120);
  
    selectedProducts.forEach((product, index) => {
      const productText = `Product ${index + 1}: ${product.productName} - Size: ${product.size} - Quantity: ${product.quantity}`;
      doc.text(productText, 60, 130 + index * 10); // Adjust the y-coordinate accordingly
    });

    doc.text('Selected Stone:', 60, 170);

    selectedProductsForStone.forEach((product, index) => {
      const productText = `Product ${index + 1}: ${product.productName} - Size: ${product.size} - Quantity: ${product.quantity}`;
      doc.text(productText, 60, 180 + index * 10); // Adjust the y-coordinate accordingly
    });
  
    doc.text(`Total Price: Rs.${totalPrice}`, 60, 260);
  
    // Save the PDF with a filename
    doc.save(suggestedFilename);
    navigate('/KitchenTypes')
  };

  return (
    <div>
  <div className="imgcontainer2">
    <img src="../assets/Quotsync.png" alt="logo" className="avatar2" />
  </div>
      <h2>Generated PDF</h2>
      <h2>to download click on below button</h2>

      <button onClick={downloadPDF}>Download PDF</button>
    </div>
  );
}

export default GeneratePDF;
