import React, { useState } from 'react';
import './SignIn.css'
import { API_URL } from '../../Config';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const {login} = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL + '/loginWithStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        login({ username: formData.username });
        navigate('/KitchenTypes');
        alert('Login successful!');
      } else if (response.status === 401) {
        alert('Invalid credentials.');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleForgetPassword = () => {
    window.location.href = '/forgetPassword';
  }


  return (
    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <form onSubmit={handleLogin}>
        <div className="imgcontainer_SignIn">
          <img src="../assets/Quotsync.png" alt="logo" className="avatar" />
        </div>
        <div className="container_SignIn">
          <h2 className="lets_Sign_you_In">LET'S SIGN YOU IN</h2>
          <input type="text_SignIn" placeholder="Username or Email" name="username" value={formData.username}
            onChange={handleInputChange} /><br />
          <hr /><br />
          <input type="password" placeholder="Password" name="password" value={formData.password}
            onChange={handleInputChange} />
          <hr /><br />
          <button className="signIn_btn_SignIn" type="submit">Sign In</button>
          <div className="forgot_pswd">
            <button className="forgot_pswd_btn_SignIn" onClick={handleForgetPassword}>Forgot Password?</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SignIn;