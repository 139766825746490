import React, { useState } from "react";
import './addCategory.css'
import { API_URL } from "../../Config";

const AddCategory = () => {
  const [formData, setFormData] = useState({
    categoryName: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCategory = async () => {
    try {
      const response = await fetch(API_URL + '/addCategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        window.location.href = '/Settings'
        alert('Category added successfully!');
      } else if (response.status === 401) {
        alert('Invalid Document.');
      } else {
        alert('Already exists');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <div className="imgcontainer">
        <img src="../assets/Quotsync.png" alt="logo" className="avatar" />
      </div>
      <div className="container">
        <h2 className="add_category">Add Category</h2>
        <input type="text" placeholder="Enter category" name="categoryName" onChange={handleInputChange} /><br />
        <hr /><br />
        <button className="add_btn_AddCategory" type="submit" onClick={handleCategory}>Add</button>
      </div>
    </div>

  )
};

export default AddCategory