import React, { useEffect, useState } from "react";
import './HardwareCompanies.css';
import { Buffer } from "buffer";
import { API_URL } from "../../Config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelection } from "../test1/SelectionContext";
import { SideMenu } from "../../SideMenu";



const SelectHardwareCompany = () => {

  const navigate = useNavigate();
  const [companyNames, setCompanyNames] = useState([]);

  const queryParams = new URLSearchParams(useLocation().search);
  const selectedType = queryParams.get('selectedType');

  const { company, setCompany } = useSelection();

  const handleCompanySelect = (selectedCompanyType) => {
    setCompany(selectedCompanyType);
    navigate('/hardware-product', {
      state: {
        selectedCompanyType: selectedCompanyType,
        selectedType: selectedType
      }
    });
  };


  useEffect(() => {
    fetch(API_URL + '/company')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const updatedData = data.map(product => {
          if (product.photo instanceof Object) {
            product.photo = Buffer.from(product.photo).toString('base64');
          }
          return product;
        });
        setCompanyNames(updatedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleAddCompany = () => {
    navigate('/addHardwarCompany');
  }


  return (
    <div>
      <SideMenu></SideMenu>
      <div className="main_companies">
        <h4 className="hardwareCompanies">HARDWARE/COMPANIES</h4>
        <div className="add_Btn_position">
          <button className="addBtn" type="submit" onClick={handleAddCompany}><img className="plus_img" src="../assets/plus.png" alt="plusImage" />&nbsp;</button>
        </div>

        <div className="company_card1Container_HardwareCompanies">
          {companyNames.map((object, index) => (
            <div key={index} className="p1_Card_HardwareCompanies" onClick={() => handleCompanySelect(object.name)}>
              <div className="company_logo1_pos">
                <img className="comapny_card1_companyLogo" src={`data:image/jpeg;base64,${object.photo}`} alt={object.name} />
              </div>
              <div className="innerCard">

                <h5>Name: {object.name}</h5>
              </div>
            </div>
          ))}
        </div>

      </div>


    </div>
  )
};

export default SelectHardwareCompany;
