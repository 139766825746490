import React from 'react';
import './SideMenu.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export const SideMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <div className="imgcontainer1">
                <img src="../assets/Quotsync.png" alt="logo" className="avatar1" />
            </div>

            <div className="sidenav1">
                <Link to="/KitchenTypes"><img src="../assets/home-icon.png" alt="home" className="home-icon1" /> </Link><br />
                <Link to="/SelectionDatabase"><img src="../assets/database-icon.png" alt="database" className="database-icon1" /></Link><br />
                <Link to="/Cloud"><img src="../assets/cloud-icon.png" alt="cloud" className="cloud-icon1" /></Link><br />
                <Link to="/Settings"><img src="../assets/settings-icon.png" alt="settings" className="settings-icon1" /></Link><br />
                <Link to="/signIn"><img src="../assets/logout-icon.png" alt="logout" className="logout-icon1" /></Link>
            </div>
        </div>
    )
};