import React, { useState } from "react";
import './KitchenDimensions.css';
import { useSelection } from "../test1/SelectionContext";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../../SideMenu";


const KitchenDimesnions = () => {
  const { lowerDimention, setLowerDimention } = useSelection();
  const { upperDimention, setUpperDimention } = useSelection();
  const { loftDimention, setLoftDimention } = useSelection();
  const { lslandDimention, setIslandDimention } = useSelection();
  const { tallDimention, setTallDimention} = useSelection();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    lowerUnit: { height: '', width: '' },
    upperUnit: { height: '', width: '' },
    loftUnit: { height: '', width: '' },
    tallUnit: { height: '', width: '' },
    islandUnit: { height: '', width: '' },
  });

  const handleInputChange = (e, unit) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [unit]: {
        ...prevData[unit],
        [name]: value,
      },
    }));
  };
  const getInputStyle = (value, opacity) => {
    return value
      ? { backgroundColor: `rgba(130, 206, 245, ${opacity})` } // Blue color with opacity
      : {};
  };

  const handleDimentionSelect = () => {
    const units = ['lowerUnit', 'upperUnit', 'loftUnit','tallUnit', 'islandUnit'];
    let isValid = true;

    units.forEach(unit => {
    
      const { height, width } = formData[unit];
  
      if (!height || !width) {
        isValid = false;
      }

      const dimentionObject = `${height}x${width}`;

      switch (unit) {
        case 'lowerUnit':
          setLowerDimention(dimentionObject);
          break;
        case 'upperUnit':
          setUpperDimention(dimentionObject);
          break;
        case 'loftUnit':
          setLoftDimention(dimentionObject);
          break;
        case 'tallUnit':
          setTallDimention(dimentionObject);
          break;
        case 'islandUnit':
          setIslandDimention(dimentionObject);
          break;
        default:
          break;
      }
    });

    if (isValid) {
      navigate('/Selection');
    } else {
      // You can handle the validation error here, e.g., show an alert or update an error state
      alert('Please fill in all dimensions before proceeding.');
    }
  };
  return (
    <div>
      <SideMenu></SideMenu>
      <div className="main_kitchenDimension">
        <p className="enterKitchenDimensions">Please enter the kitchen dimensions</p><br />
        <i className="heightxWidth_1">Lower unit In height x width
          <input className="inputHeight" type="text" placeholder="Height" name="height" value={formData.lowerUnit.height} style={getInputStyle(formData.lowerUnit.height, 0.5)}
            onChange={(e) => handleInputChange(e, 'lowerUnit')} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;X
          <input className="inputWidth" type="text" placeholder="Width" name="width" value={formData.lowerUnit.width} style={getInputStyle(formData.lowerUnit.width, 0.5)}
            onChange={(e) => handleInputChange(e, 'lowerUnit')} />
        </i><br />
        <br></br>
        <i className="heightxWidth_2">Upper unit In height x width
          <input className="inputHeight" type="text" placeholder="Height" name="height" value={formData.upperUnit.height} style={getInputStyle(formData.upperUnit.height, 0.5)}
            onChange={(e) => handleInputChange(e, 'upperUnit')} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;X
          <input className="inputWidth" type="text" placeholder="Width" name="width" value={formData.upperUnit.width} style={getInputStyle(formData.upperUnit.width, 0.5)}
            onChange={(e) => handleInputChange(e, 'upperUnit')} />
        </i>
        <br />
        <br></br>
        <i className="heightxWidth_3">Loft unit In height x width
          <input className="inputHeight" type="text" placeholder="Height" name="height" value={formData.loftUnit.height} style={getInputStyle(formData.loftUnit.height, 0.5)}
            onChange={(e) => handleInputChange(e, 'loftUnit')} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;X
          <input className="inputWidth" type="text" placeholder="Width" name="width" value={formData.loftUnit.width} style={getInputStyle(formData.loftUnit.width, 0.5)}
            onChange={(e) => handleInputChange(e, 'loftUnit')} />
        </i>
        <br />
        <br></br>
        <i className="heightxWidth_3">Tall unit In height x width
          <input className="inputHeight" type="text" placeholder="Height" name="height" value={formData.tallUnit.height} style={getInputStyle(formData.tallUnit.height, 0.5)}
            onChange={(e) => handleInputChange(e, 'tallUnit')} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;X
          <input className="inputWidth" type="text" placeholder="Width" name="width" value={formData.tallUnit.width} style={getInputStyle(formData.tallUnit.width, 0.5)}
            onChange={(e) => handleInputChange(e, 'tallUnit')} />
        </i>
        <br />
        <br></br>
        <i className="heightxWidth_4">Island unit In height x width
          <input className="inputHeight" type="text" placeholder="Height" name="height" value={formData.islandUnit.height} style={getInputStyle(formData.islandUnit.height, 0.5)}
            onChange={(e) => handleInputChange(e, 'islandUnit')} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;X
          <input className="inputWidth" type="text" placeholder="Width" name="width" value={formData.islandUnit.width} style={getInputStyle(formData.islandUnit.width, 0.5)}
            onChange={(e) => handleInputChange(e, 'islandUnit')} />
        </i>
        <div className="next_Btn_position_KitchenDimensions">
          <button className="nextBtn_KitchenDimensions" type="submit" onClick={handleDimentionSelect}><img className="img_rightArrow" src="../assets/rightArrow.png"></img></button>
        </div>
      </div>


    </div>



  )
};

export default KitchenDimesnions;