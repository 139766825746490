import React, { useState } from 'react';
import './ForgetPassword.css'
import { API_URL } from '../../Config';

const ForgetPassword = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [otpMessage, setOtpMessage] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    otp: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendOtp = async () => {

    formData.otp = formData.otp.toString();
    const response = await fetch(API_URL + '/send-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("data==>", data);
      setOtpMessage('Otp send!');
    }
  };

  const handleForgetPassword = async () => {
    formData.otp = formData.otp.toString();
    try {
      const response = await fetch(API_URL + '/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setSuccessMessage('Password changed successfully!')
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } else {
        alert('Invalid Otp');
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <div className="imgcontainer">
        <img src="../assets/Quotsync.png" alt="logo" className="avatar" />
      </div>
      <div className="container">
        <h2 className="forgot_password">Forgot Password</h2>
        <input type="text_ForgotPswd" placeholder="Enter your Email" name="email" value={formData.email} onChange={handleInputChange} /><br />
        <hr /><br />
        <input type="password_ForgotPswd" placeholder="Enter OTP" name="otp" value={formData.otp} onChange={handleInputChange} />
        {otpMessage && <label>{otpMessage}</label>}<br />
        <hr /><br />
        <input type="password_ForgotPswd" placeholder="Enter new Password" name="password" value={formData.password} onChange={handleInputChange} required />
        <hr /><br />
        {successMessage && <label>{successMessage}</label>}<br />
        <button className="sendOTP_btn_ForgotPswd" type="submit" onClick={handleSendOtp}>Send OTP</button><br />

        <button className="signIn_btn_ForgotPswd" type="submit" onClick={handleForgetPassword}>Sign In</button>
      </div>
    </div>
  )
}

export default ForgetPassword;