import React, { useEffect, useRef, useState } from 'react'
import './Quotation.css'
import { useNavigate } from 'react-router-dom';
import { useSelection } from './SelectionContext';
import { API_URL } from '../../Config';

const Quotation = () => {
	const ref = useRef()
	const navigate = useNavigate();
	const { kitchenType, lowerDimention, upperDimention, loftDimention, tallDimention, islandDimention, selectedProducts, selectedProductsForStone, selectedProductsForShutter, selectedProductsForCarcass, selectedProductsForAppliances, totalForShutter, totalForAppliances, totalForCarcase, totalAfterDiscount, totalPrice, totalForProducts, totalForStones, calculatePrice, resetState } = useSelection();
	const [showButton, setShowButton] = useState(true);
	const [pdfData, setPdfData] = useState(null); // Add new state variable

	const categories = ["HARDWARE", "SHUTTER", "CARCASS", "APPLIANCES", "STONE"];

	useEffect(() => {
		calculatePrice();
	}, [selectedProducts, selectedProductsForStone, selectedProductsForShutter, calculatePrice]);

	const generatePdf = () => {
		setShowButton(false);

		const clientName = document.getElementById('clientName').value;
		const contactNumber = document.getElementById('contactNumber').value;

		if (!clientName || !contactNumber) {
			alert('Please enter both client name and contact number.');
			setShowButton(true);
			return;
		}

		const styles = `
		  <style>
			body {
			  font-family: Arial, sans-serif;
			}
			
			table {
			  width: 100%;
			  border-collapse: collapse;
			  margin-bottom: 20px;
			}
			
			th, td {
			  border: 1px solid #dddddd;
			  text-align: left;
			  padding: 8px;
			}
			
			th {
			  background-color: #758D93;
			}
			.input_quotation{
				border:none;
				outline:none;
			}
			.title_quotation{
				color:white;
			}
		  </style>
		`;

		const finalPdfData = `
		<!DOCTYPE html>
		<html>
		  <head>
			${styles}
		  </head>
		  <body>
			<h1>Modular Kitchen Quotation</h1>
			
			<table>
			  <thead>
				<tr>
				  <th class='title_quotation'>Client Details</th>
				  <th class='title_quotation'>Details</th>
				</tr>
			  </thead>
			  <tr>
				<td>Client Name</td>
				<td>${clientName}</td>
			  </tr>
			  <tr>
				<td>Contact Number</td>
				<td>${contactNumber}</td>
			  </tr>
			</table>
			
			${ref.current.innerHTML}
		  </body>
		</html>
		`;

		const handleAfterPrint = async () => {
			setShowButton(true);
			setPdfData(finalPdfData);
			window.removeEventListener('afterprint', handleAfterPrint);

			try {
				const response = await fetch(API_URL + '/store-pdf', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						clientName: clientName,
						contactNumber: contactNumber,
						pdfData: finalPdfData,
					}),
				});

				if (response.ok) {
					console.log('PDF stored successfully');
				} else {
					console.error('Failed to store PDF:', response.statusText);
				}
			} catch (error) {
				console.error('Error storing PDF:', error);
			}

			// Other actions after printing
			resetState();
			navigate('/KitchenTypes');
			// window.location.reload(true);
		};

		window.addEventListener('afterprint', handleAfterPrint);
		setTimeout(() => {
			window.print();
		}, 100);
	};



	const renderCategoryTable = (category) => {
		const selectedProducts = getSelectedProducts(category);

		if (!selectedProducts) {
			return null;
		}

		return (
			<tr key={category}>
				<td>
					{category}
					<table>
						<thead>
							<tr>
								<th className='title_quotation'>Product Name</th>
								<th className='title_quotation'>Product Size</th>
								<th className='title_quotation'>Quantity</th>
							</tr>
						</thead>
						<tbody>
							{getSelectedProducts(category).map((product, index) => (
								<tr key={index}>
									<td>{product.productName}</td>
									<td>{product.size}</td>
									<td>{product.quantity}</td>
								</tr>
							))}
						</tbody>
					</table>
				</td>
				<td>{getTotalForCategory(category)}</td>
			</tr>
		);
	};

	const getSelectedProducts = (category) => {
		switch (category) {
			case "HARDWARE":
				return selectedProducts.length > 0 ? selectedProducts : null;
			case "SHUTTER":
				return selectedProductsForShutter.length > 0 ? selectedProductsForShutter : null;
			case "CARCASS":
				return selectedProductsForCarcass.length > 0 ? selectedProductsForCarcass : null;
			case "APPLIANCES":
				return selectedProductsForAppliances.length > 0 ? selectedProductsForAppliances : null;
			case "STONE":
				return selectedProductsForStone.length > 0 ? selectedProductsForStone : null;
			default:
				return [];
		}
	};

	const getTotalForCategory = (category) => {
		switch (category) {
			case "HARDWARE":
				return totalForProducts;
			case "SHUTTER":
				return totalForShutter;
			case "CARCASS":
				return totalForCarcase;
			case "APPLIANCES":
				return totalForAppliances;
			case "STONE":
				return totalForStones;

			default:
				return 0;
		}
	};

	return (
		<div>

			<div className="quotation-details">
				<h1>Modular Kitchen Quotation</h1>

				<table>
					<thead>
						<tr>
							<th className='title_quotation'>Client Details</th>
							<th className='title_quotation'>Details</th>
						</tr>
					</thead>
					<tr>
						<td>Client Name</td>
						<td><input id="clientName" className='input_quotation' type="text" placeholder="Enter client name" /></td>
					</tr>
					<tr>
						<td>Contact Number</td>
						<td><input id="contactNumber" className='input_quotation' type="text" placeholder="Enter contact number" pattern='\d' maxLength={10} /></td>
					</tr>
				</table>
				<div ref={ref}>
					<table>
						<tr>
							<th className='title_quotation'>Kitechen Details</th>
							<th className='title_quotation'>Measurement</th>
						</tr>
						<tbody>
							<tr>
								<td>Kitechen Type</td>
								<td>{kitchenType}</td>
							</tr>
							<tr>
								<td>Lower Unit</td>
								<td>{lowerDimention}</td>
							</tr>
							<tr>
								<td>Upper Unit</td>
								<td>{upperDimention}</td>
							</tr>
							<tr>
								<td>Loft Unit</td>
								<td>{loftDimention}</td>
							</tr>
							<tr>
								<td>Tall Unit</td>
								<td>{tallDimention}</td>
							</tr>
							<tr>
								<td>Island Unit</td>
								<td>{islandDimention}</td>
							</tr>
						</tbody>
					</table>
					<br />
					<table>
						<thead>
							<tr>
								<th className='title_quotation'>Particulars</th>
								<th className='title_quotation'>Details</th>
							</tr>
						</thead>
						<tbody>
							{categories.map((category) => renderCategoryTable(category))}
						</tbody>
					</table>
					<br />
					<table>
						<tr>
							<th className='title_quotation'>Total Price</th>
							<th className='title_quotation'>{totalPrice}</th>
						</tr>
					</table>
					<table>
						<tr>
							<th className='title_quotation'>Total Price after discount</th>
							<th className='title_quotation'>{totalAfterDiscount}</th>
						</tr>
					</table>
				</div>
			</div>
			{showButton && <button onClick={generatePdf}>Generate PDF</button>}
		</div>
	)
}

export default Quotation;
