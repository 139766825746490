import React from "react";
import './kitchenTypes.css';
import { useSelection } from "../test1/SelectionContext";
import { useNavigate } from 'react-router-dom';
import { SideMenu } from "../../SideMenu";


const KitchenTypes = () => {
  const navigate = useNavigate();
  const { kitchenType, setKitchenType } = useSelection();

  const handleKitchenTypeSelect = (selectedType) => {
    setKitchenType(selectedType);
    navigate('/KitchenDimentions');
  };


  return (
    <div>
      <SideMenu></SideMenu>

      <div className="main_kitchen">
        <h4 className="typeOfKitchen">KITCHEN SELECTION</h4><br /><br />
        <div className="gridContainer">
          <div className="card1_KitchenTypes" >
            <img src="../assets/island-kitchen.jpg" className="card1-img" alt="straight kitchen" />
            <div className="card-body">
              <button className="g_btn" onClick={() => handleKitchenTypeSelect('G Kitchen')}> G Kitchen</button>
            </div>
          </div>
          <div className="card2_KitchenTypes" >
            <img src="../assets/island-kitchen.jpg" className="card2-img" alt="straight kitchen" />
            <div className="card-body">
              <button className="straight_btn" onClick={() => handleKitchenTypeSelect('Straight Kitchen')}>Straight Kitchen</button>
            </div>
          </div>
          <div className="card3_KitchenTypes" >
            <img src="../assets/island-kitchen.jpg" className="card3-img" alt="straight kitchen" />
            <div className="card-body">
              <button className="u_btn" onClick={() => handleKitchenTypeSelect('U Kitchen')}>U Kitchen</button>
            </div>
          </div>
          <div className="card4_KitchenTypes" >
            <img src="../assets/island-kitchen.jpg" className="card4-img" alt="straight kitchen" />
            <div className="card-body">
              <button className="island_btn" onClick={() => handleKitchenTypeSelect('Island Kitchen')}>Island Kitchen</button>
            </div>
          </div>
          <div className="card5_KitchenTypes" >
            <img src="../assets/island-kitchen.jpg" className="card5-img" alt="straight kitchen" />
            <div className="card-body">
              <button className="l_btn" onClick={() => handleKitchenTypeSelect('L Kitchen')}>L Kitchen</button>
            </div>
          </div>
          <div className="card6_KitchenTypes" >
            <img src="../assets/island-kitchen.jpg" className="card6-img" alt="straight kitchen" />
            <div className="card-body">
              <button className="parallel_btn" onClick={() => handleKitchenTypeSelect('Parallel Kitchen')}>Parallel Kitchen</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
};

export default KitchenTypes;