import React, { useEffect, useState } from "react";
import './HardwareProductName.css';
import { API_URL } from "../../Config";
import { Buffer } from "buffer";
import { useSelection } from "../test1/SelectionContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { SideMenu } from "../../SideMenu";
import isEqual from 'lodash/isEqual';


const HardwareProductName = () => {

  const navigate = useNavigate();

  const [availableSizes, setAvailableSizes] = useState({});
  const [selectedSizes, setSelectedSizes] = useState({});
  const [names, setNames] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const location = useLocation();
  const { selectedType, selectedCompanyType } = location.state;

  const { selectedProducts, setSelectedProducts } = useSelection();

  const handleProductSelect = (productName, size, quantity, isChecked) => {
    setSelectedProducts((prevSelectedProducts) => {
      const productIndex = prevSelectedProducts.findIndex(
        (product) => isEqual(product, { productName, size, quantity: product.quantity })
      );

      if (isChecked) {
        // If the checkbox is checked, add the product to the array
        if (productIndex === -1) {
          return [...prevSelectedProducts, { productName, size, quantity }];
        }
      } else {
        // If the checkbox is unchecked, remove the product from the array
        if (productIndex !== -1) {
          const updatedProducts = [...prevSelectedProducts];
          updatedProducts.splice(productIndex, 1);
          return updatedProducts;
        }
      }

      // If the checkbox is checked and the product is already in the array, update the quantity
      if (productIndex !== -1) {
        const updatedProducts = [...prevSelectedProducts];
        updatedProducts[productIndex].quantity = quantity;
        return updatedProducts;
      }

      // Default case: return the current array
      return prevSelectedProducts;
    });
  };


  const handleNextClick = () => {
    if (selectedProducts.length > 0) {
      navigate('/Selection');
    } else {
      alert('Please select at least one product before proceeding.');
    }
  };

  useEffect(() => {
    fetch(API_URL + `/hardwareProducts/?company=${selectedCompanyType}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const updatedData = data.map(product => {
          if (product.photo instanceof Object) {
            product.photo = Buffer.from(product.photo).toString('base64');
            const decodedPhoto = Buffer.from(product.photo, 'base64').toString('ascii');
            product.photo = decodedPhoto;
          }
          return { ...product, quantity: 1 };
        });
        setNames(updatedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleIncrement = (product) => {
    setNames((prevNames) => {
      const updatedNames = prevNames.map((prevProduct) =>
        prevProduct.name === product.name ? { ...prevProduct, quantity: prevProduct.quantity + 1 } : prevProduct
      );
      return updatedNames;
    });
  };

  const handleDecrement = (product) => {
    setNames((prevNames) => {
      const updatedNames = prevNames.map((prevProduct) =>
        prevProduct.name === product.name && prevProduct.quantity > 1
          ? { ...prevProduct, quantity: prevProduct.quantity - 1 }
          : prevProduct
      );
      return updatedNames;
    });
  };


  useEffect(() => {
    // Fetch sizes for each product from the database
    const fetchSizes = async () => {
      const sizesPromises = names.map(async (object) => {
        try {
          const response = await fetch(API_URL + `/hardware/sizes/?productName=${object.name}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error(`Error fetching sizes for ${object.name}:`, error);
          return [];
        }
      });

      const sizes = await Promise.all(sizesPromises);
      setAvailableSizes(sizes);
    };

    fetchSizes();
  }, [names]); // Add names as a dependency so that the effect is re-run when the list of products changes

  const uniqueNames = Array.from(new Set(names.map((object) => object.name)));

  const filteredProducts = uniqueNames.filter((name) =>
    name.toLowerCase().includes(filterValue.toLowerCase())
  );

  const handleAddProduct = () => {
    navigate('/addHardwarProduct');
  }

  const handleDeleteProduct = async (productId) => {
    try {
      const userConfirmed = window.confirm('Are you sure you want to delete this product?');
      if (!userConfirmed) {
        return;
      }
      const response = await fetch(API_URL + `/${selectedType}/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      // If the API call is successful, update the UI state (remove product from names)
      const updatedNames = names.filter((product) => product.name !== productId);
      setNames(updatedNames);

      // If the product was selected, remove it from the selected products state
      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter(
          (product) => product.productId !== productId
        )
      );

      console.log('Product deleted successfully');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const renderFilteredProducts = () => {
    return filteredProducts.map((uniqueName, index) => {
      const productsWithSameName = names.filter((item) => item.name === uniqueName);
      const object = productsWithSameName[0]; // Use the first instance for common properties
      const imageUrl = `https://quotsync.inittechnology.co/Quotsync_backend/uploads/${object.photo}`;
      const sizesForProduct = availableSizes.find((sizes) => sizes.includes(object.size)) || [];

      return (
        <div key={index} className="p1_Card">
          <button className="trashBtn" type="submit" onClick={() => handleDeleteProduct(object.name)}>
            <img src="../assets/Trash.png" height="20px" alt="trash_img" />
          </button>

          <div className="logo1_pos">
            <img
              className="card1_companyLogo"
              src={imageUrl}
              alt={object.name}
            />
          </div>

          <h5>Name: {object.name}</h5>

          <h5>Size:   <select
            value={selectedSizes[object.name]}
            onChange={(e) => {
              const newSize = e.target.value;
              setSelectedSizes((prevSelectedSizes) => ({
                ...prevSelectedSizes,
                [object.name]: newSize,
              }));
            }}
          >
            <option selected disabled hidden>
              Select
            </option>
            {sizesForProduct.map((size, sizeIndex) => (
              <option key={sizeIndex}>{size}</option>
            ))}
          </select> </h5>

          {/* <h5>Code: {object.code}</h5> */}

          <div className="quantity-counter">
            <button onClick={() => handleDecrement(object)} className="quantity-btn">
              -
            </button>
            <span className="quantity-display">{isNaN(object.quantity) ? '0' : object.quantity}</span>
            <button onClick={() => handleIncrement(object)} className="quantity-btn">
              +
            </button>
            <br />
            <span className="checkbox_pos">
              <input
                className="checkbox1"
                type="checkbox"
                checked={selectedProducts.some((product) =>
                  isEqual(product, { productName: object.name, size: selectedSizes[object.name], quantity: object.quantity })
                )}
                onChange={(e) => handleProductSelect(object.name, selectedSizes[object.name], object.quantity, e.target.checked)}
                disabled={!selectedSizes[object.name]}
              />
            </span>
          </div>
        </div>
      );
    });
  };

  const handleSearchInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <div>
      <SideMenu></SideMenu>
      <div className="main_hproduct">
        <h4 className="hardwareCompanyName">HARDWARE/COMPANY NAME</h4>

        <div className="add_Btn_position">
          <button className="addBtn" type="submit" onClick={handleAddProduct}><img className="plus_img" src="../assets/plus.png" alt="plusImage" />&nbsp;</button>
        </div>
        <div className='searchBar_pos_productInfo'>
          &nbsp;<img className='search_icon' src='../assets/search.png' alt='search' />
          <input className='searchInput' type="search" id="gsearch" name="gsearch" placeholder='Search' value={filterValue}
            onChange={handleSearchInputChange}></input>
        </div>

        <div className="card1Container">
          {renderFilteredProducts()}
        </div>

        <div className="next_Btn_position_HardwareProductName">
          <button className="nextBtn_HardwareProductName" type="submit" onClick={handleNextClick}><img className="img_rightArrow" src="../assets/rightArrow.png"></img></button>
        </div>

      </div>
    </div>
  )
};

export default HardwareProductName;
